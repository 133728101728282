/* eslint-disable import/no-cycle */
import localForage from 'localforage';
import APIController from './APIController';

export default class AuthController {
  static async isLogged() {
    const t = await AuthController.getToken();
    if (!t) return false;
    const api = await APIController.getConnection();
    try {
      const { data } = await api.get('/auth');
      await AuthController.setUser({ panel: true }, data.token);
      return true;
    } catch (e) {
      if (e.message === 'Network Error') {
        throw e;
      }
      return false;
    }
  }

  static async getUser() {
    return localForage.getItem('@Auth:User');
  }

  static async getToken() {
    return localForage.getItem('@Auth:Token');
  }

  static async logout(goTo = '/') {
    await localForage.removeItem('@Auth:Token');
    window.location.href = goTo;
  }

  static async setUser(user, token) {
    await localForage.setItem('@Auth:Token', token);
    await localForage.setItem('@Auth:User', user);
  }
}

const defaultOptions = {
  decimalDigits: 2,
  hideZeroes: false,
};

export function FloatToCurrency(number) {
  return Math.trunc(parseFloat(number) * 100);
}

export function CurrencyToFloat(currency) {
  return parseInt(currency, 10) / 100;
}

export function FormatCurrency(currency, options = defaultOptions) {
  const formatted = Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: options.decimalDigits ?? defaultOptions.decimalDigits,
  }).format(CurrencyToFloat(currency));
  return !options.hideZeroes ? formatted : formatted.replace(',00', '');
}

export function FormatedStringToCurrency(str) {
  return parseInt(str.replace(/\D/g, ''), 10);
}

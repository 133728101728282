/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { setGlobal } from '@logicamente.info/global-emitter';
import { format } from 'date-fns';
import { zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';
import serialize from 'serialize-javascript';
import Dropdown from 'react-bootstrap/Dropdown';
import Template from './Template';
import ModalSolicitacao from './Solicitacoes/ModalSolicitacao';
import ModalEncaminhar from './Solicitacoes/ModalEncaminhar';
import ModalAlterarSituacao from './Solicitacoes/ModalAlterarSituacao';
import APIController from '../Controllers/APIController';
import Pagination from '../Utils/Pagination';
import { FormatCurrency } from '../Utils/Currency';
import PrepareToSearch from '../Utils/PrepareToSearch';
import SelectMultipleCidades from './Template/SelectMultipleCidades';
import SelectMultipleSituacoes from './Template/SelectMultipleSituacoes';

const offset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);

const Solicitacoes = () => {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [filtering, setFiltering] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [situacao, setSituacao] = useState('Solicitado');
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [solicitacoesNovas, setSolicitacoesNovas] = useState(0);
  const [solicitacoesEmAnalise, setsolicitacoesEmAnalise] = useState(0);
  const [solicitacoesAprovadas, setSolicitacoesAprovadas] = useState(0);
  const [solicitacoesReprovadas, setSolicitacoesReprovadas] = useState(0);
  const [editarSolicitacao, setEditarSolicitacao] = useState(false);
  const [alterarSituacao, setAlterarSituacao] = useState(false);
  const [encaminharSolicitacao, setEncaminharSolicitacao] = useState(false);

  function carregarParametrosFiltro() {
    const params = { filter: {} };
    params.filter.situacao = situacao;
    if (filtering) {
      if (filter.nome) params.filter.nome = PrepareToSearch(filter.nome);
      if (filter.tipoSolicitacao) params.filter.tipoSolicitacao = filter.tipoSolicitacao;
      if (filter.origem) {
        if (filter.origem === 'site') {
          params.filter.$or = [
            { origem: 'site' }, { origem: null },
          ];
        } else params.filter.origem = filter.origem;
      }
      if (filter.fim && filter.fim !== '') {
        const oFim = new Date(filter.fim);
        oFim.setDate(oFim.getDate() + 1);
        oFim.setTime(oFim.getTime() - offset);
        params.filter.dataHora = { $lt: zonedTimeToUtc(oFim).toISOString() };
      }
      if (filter.inicio && filter.inicio !== '') {
        const oInicio = new Date(filter.inicio);
        oInicio.setTime(oInicio.getTime() - offset);
        params.filter.dataHora = {
          ...params.filter.dataHora,
          $gte: zonedTimeToUtc(oInicio).toISOString(),
        };
      }

      if (filter.cidades) {
        params.filter.cidade = {
          $in: filter.cidades.map((c) => c.value),
        };
      }

      if (filter.situacoes?.length === 0) {
        params.situacoes = null;
      } else {
        params.situacoes = filter.situacoes?.map((s) => s.value) ?? null;
      }
    }
    return params;
  }

  const filterParams = carregarParametrosFiltro();

  async function carregarSolicitacoes() {
    setEditarSolicitacao(false);
    setAlterarSituacao(false);
    setEncaminharSolicitacao(false);
    setLoading(true);
    const api = await APIController.getConnection();
    const { data } = await api.get(`solicitacao/${page}/20`, { params: filterParams });
    setTotalPages(data.pages);
    setSolicitacoesNovas(data.novas);
    setsolicitacoesEmAnalise(data.emAnalise);
    setSolicitacoesAprovadas(data.aprovados);
    setSolicitacoesReprovadas(data.reprovados);
    setSolicitacoes(data.items);
    setLoading(false);
  }

  function avaliarCorBadge(tipo) {
    switch (tipo) {
      case 'Crédito - Giro': return 'badge-primary';
      case 'Crédito - Fixo': return 'badge-info';
      case 'Crédito Pessoal': return 'badge-warning';
      default: return 'badge-success';
    }
  }

  function exibirBadge(tipo, block) {
    const color = avaliarCorBadge(tipo);
    return (
      <span className={`badge badge-pill align-items-center ${color} ${block}`}>
        {tipo}
      </span>
    );
  }

  function alterarSolicitacao(solic) {
    setGlobal('@Solicitacao:editar', solic);
    setEditarSolicitacao(true);
  }

  function alterarSituacaoSolic(solic) {
    setGlobal('@Solicitacao:situacao', solic);
    setAlterarSituacao(true);
  }

  function encaminhaSolicitacao(solic) {
    setGlobal('@Solicitacao:encaminhar', solic);
    setEncaminharSolicitacao(true);
  }

  function onChangeFilter(param, value) {
    const f = { ...filter };
    f[param] = value;
    setFilter(f);
  }

  function onResetFilter() {
    setFiltering(false);
    setFilter({});
    carregarSolicitacoes();
  }

  function OnFilterSubmit(e) {
    e.preventDefault();
    setFiltering(true);
    if (filtering) carregarSolicitacoes();
  }

  useEffect(() => {
    carregarSolicitacoes();
  }, [situacao, page, filtering]);

  return (
    <Template module="Solicitações">
      <Helmet>
        <title>Solicitações | Banco do Povo - Painel</title>
      </Helmet>
      <div className="d-sm-flex justify-content-between align-items-center mb-4">
        <h3 className="text-dark mb-0">Solicitações</h3>
      </div>
      <div className="row">
        <div className="col">
          <form onSubmit={OnFilterSubmit}>
            <div className="form-row">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-auto">
                <div className="form-group">
                  <label htmlFor="credito">Tipos de Crédito</label>
                  <select
                    className="custom-select "
                    id="credito"
                    value={filter.tipoSolicitacao}
                    onChange={(e) => onChangeFilter('tipoSolicitacao', e.target.value)
                    }
                  >
                    <option value="">Todos</option>
                    <option value="Crédito - Giro">Crédito - Giro</option>
                    <option value="Crédito - Fixo">Crédito - Fixo</option>
                    <option value="Crédito Pessoal">Crédito Pessoal</option>
                    <option value="Meu Cantinho">Meu Cantinho</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-8 col-xl-2">
                <div className="form-group">
                  <label htmlFor="nome">Nome</label>
                  <input
                    className="form-control"
                    type="text"
                    id="nome"
                    value={filter.nome ?? ''}
                    onChange={(e) => onChangeFilter('nome', e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-auto">
                <div className="form-group">
                  <label htmlFor="nome">Período</label>
                  <input
                    className="form-control"
                    type="date"
                    value={filter.inicio ?? ''}
                    onChange={(e) => onChangeFilter('inicio', e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-auto">
                <div className="form-group">
                  <label htmlFor="nome">até</label>
                  <input
                    className="form-control"
                    type="date"
                    value={filter.fim ?? ''}
                    onChange={(e) => onChangeFilter('fim', e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-auto">
                <div className="form-group">
                  <label htmlFor="origem">Origem</label>
                  <select
                    className="custom-select "
                    id="origem"
                    value={filter.origem}
                    onChange={(e) => onChangeFilter('origem', e.target.value)
                    }
                  >
                    <option value="">Todas</option>
                    <option value="app">Aplicativo</option>
                    <option value="site">Site</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3 col-xl-9">
                <div className="form-group">
                  <label>Cidades:</label>
                  <SelectMultipleCidades
                    value={filter.cidades}
                    onChange={(e) => onChangeFilter('cidades', e)}
                    closeMenuOnSelect={true}
                    placeholder="Todas"
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group">
                  <label>Situações:</label>
                  <SelectMultipleSituacoes
                    value={filter.situacoes}
                    onChange={(e) => onChangeFilter('situacoes', e)}
                  />
                </div>
              </div>

              <div className="col-sm-12 d-flex justify-content-end align-items-end">
                <div className="form-group d-flex flex-row">
                  <Dropdown className="mr-1">
                    <Dropdown.Toggle>
                      <i className="fas fa-print" /> Imprimir
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href={`/imprimir?p=${serialize(filterParams)}`}
                        rel="noopener noreferrer"
                        target="_blank">
                        Relatório analítico
                      </Dropdown.Item>
                      <Dropdown.Item
                        href={`/imprimir-sintetico?p=${serialize(filterParams)}`}
                        rel="noopener noreferrer"
                        target="_blank">
                        Relatório sintético
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {filtering && (
                    <button
                      className="btn btn-link text-danger"
                      onClick={onResetFilter}
                    >
                      <i className="fas fa-times" /> Limpar filtro
                    </button>
                  )}
                  <button className="btn btn-dark" type="submit">
                    <i className="fas fa-search" /> Filtrar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-3">
          <button
            className={`btn btn-block ${situacao === 'Solicitado' ? 'btn-primary' : 'btn-light'
            }`}
            type="button"
            onClick={() => setSituacao('Solicitado')}
          >
            Solicitados ({solicitacoesNovas})
          </button>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <button
            className={`btn btn-block ${situacao === 'Em análise' ? 'btn-primary' : 'btn-light'
            }`}
            type="button"
            onClick={() => setSituacao('Em análise')}
          >
            Em análise ({solicitacoesEmAnalise})
          </button>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <button
            className={`btn btn-block ${situacao === 'Aprovado' ? 'btn-primary' : 'btn-light'
            }`}
            type="button"
            onClick={() => setSituacao('Aprovado')}
          >
            Aprovados ({solicitacoesAprovadas})
          </button>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <button
            className={`btn btn-block ${situacao === 'Reprovado' ? 'btn-primary' : 'btn-light'
            }`}
            type="button"
            onClick={() => setSituacao('Reprovado')}
          >
            Reprovados ({solicitacoesReprovadas})
          </button>
        </div>
      </div>
      {loading && (
        <div className="row">
          <div className="col-12 text-center">
            <span className="spinner spinner-border" />
            <br />
            Carregando dados...
          </div>
        </div>
      )}
      {!loading && (
        <div className="row">
          <div className="col-12">
            {solicitacoes.map((s) => (
              <div className="card my-2" key={s._id}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5">
                      <label className="d-flex justify-content-between m-0">
                        <strong>Nome</strong>
                        {exibirBadge(s.tipoSolicitacao, 'd-flex d-md-none')}
                      </label>
                      <label className="d-block">
                        {s.nome}
                        <br />
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-2">
                      <label className="d-flex justify-content-between m-0">
                        <strong>Solicitado em</strong>
                        {exibirBadge(
                          s.tipoSolicitacao,
                          'd-none d-md-flex d-lg-none',
                        )}
                      </label>
                      <label className="d-block m-0">
                        {format(new Date(s.dataHora), 'dd/MM/yyyy HH:mm:ss')}
                        <br />
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2 pb-0">
                      <label className="d-flex justify-content-between m-0">
                        <strong>Telefone</strong>
                        {exibirBadge(
                          s.tipoSolicitacao,
                          'd-none d-lg-flex d-xl-none align-items-lg-center',
                        )}
                      </label>
                      <label className="d-block m-0">
                        (69) {s.telefone}
                        <br />
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-6 col-xl-3">
                      <label className="d-flex justify-content-between m-0">
                        <strong>Cidade</strong>
                        {exibirBadge(s.tipoSolicitacao, 'd-none d-xl-flex')}
                      </label>
                      <label className="d-block m-0">
                        {s.cidade}
                        <br />
                      </label>
                    </div>
                    <div className="col-auto">
                      <label>
                        <strong>Valor solicitado</strong>
                      </label>
                      <label className="d-block m-0">
                        R$ {FormatCurrency(s.valor)}
                      </label>
                    </div>
                    <div className="col-auto">
                      <label>
                        <strong>Parcelas</strong>
                      </label>
                      <label className="d-block m-0">{s.parcelas}</label>
                    </div>
                    <div className="col-auto">
                      <label>
                        <strong>Valor das parcelas</strong>
                      </label>
                      <label className="d-block m-0">
                        R$ {FormatCurrency(s.valorFinal / s.parcelas)}
                      </label>
                    </div>
                    <div className="col-auto">
                      <label>
                        <strong>Origem</strong>
                      </label>
                      <label className="d-block m-0">
                        {s.origem
                          ? s.origem[0].toUpperCase() + s.origem.slice(1)
                          : 'App'}
                      </label>
                    </div>
                    <div className="col d-flex justify-content-end align-items-end">
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        style={{ height: '34px' }}
                      >
                        <button
                          className="btn btn-info"
                          type="button"
                          onClick={() => alterarSolicitacao(s)}
                        >
                          <i className="far fa-eye" />
                        </button>
                        <button
                          className="btn btn-warning"
                          type="button"
                          onClick={() => alterarSituacaoSolic(s)}
                        >
                          <i className="fas fa-exchange-alt" />
                        </button>
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => encaminhaSolicitacao(s)}
                        >
                          <i className="far fa-envelope" />
                          <span className="d-none d-sm-inline-block d-md-none d-lg-inline-block">
                            &nbsp;Encaminhar
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col d-flex d-md-flex d-xl-flex justify-content-center">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onChangePage={setPage}
            />
          </div>
        </div>
      )}
      <ModalSolicitacao
        show={editarSolicitacao}
        onSubmit={carregarSolicitacoes}
        onClose={() => setEditarSolicitacao(false)}
      />
      <ModalEncaminhar
        show={encaminharSolicitacao}
        onSubmit={carregarSolicitacoes}
        onClose={() => setEncaminharSolicitacao(false)}
      />
      <ModalAlterarSituacao
        show={alterarSituacao}
        onSubmit={carregarSolicitacoes}
        onClose={() => setAlterarSituacao(false)}
      />
    </Template>
  );
};

export default Solicitacoes;

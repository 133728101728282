import React from 'react';
import Select from 'react-select';

const Default = [
  { value: 'Solicitado', label: 'Solicitados' },
  { value: 'Em análise', label: 'Em análise' },
  { value: 'Aprovado', label: 'Aprovados' },
  { value: 'Reprovado', label: 'Reprovados' },
];

function SelectMultipleSituacoes({ value, onChange }) {
  return (
    <Select
      isMulti
      options={Default}
      value={value && value.length > 0 ? value : Default}

      onChange={onChange}
      closeMenuOnSelect={false}
      noOptionsMessage={() => 'Todas as situações estão selecionadas'}
    />
  );
}

export default SelectMultipleSituacoes;

import React from 'react';
import Select from 'react-select';
import rondonia from '../../Assets/rondonia.json';

const options = rondonia.map((c) => ({ value: c, label: c }));

function SelectMultipleCidades({
  value,
  onChange,
  placeholder = 'Selecione uma ou mais cidades...',
  closeMenuOnSelect = false,
}) {
  return (
    <Select
      isMulti
      options={options}
      value={value ?? []}
      onChange={onChange}
      closeMenuOnSelect={closeMenuOnSelect}
      placeholder={placeholder}
    />
  );
}

export default SelectMultipleCidades;

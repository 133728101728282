const PrepareToSearch = (str) => {
  const nStr = str
    .replace(/ /g, '[\\w ]{0,}')
    .replace(/[aáàãä]/gi, '[aáàãä]')
    .replace(/[eéèẽë]/gi, '[eéèẽë]')
    .replace(/[iíìĩï]/gi, '[iíìĩï]')
    .replace(/[oóòõö]/gi, '[oóòõö]')
    .replace(/[uúùũü]/gi, '[uúùũü]');
  return `/${nStr}/i`;
};

export default PrepareToSearch;

/* eslint-disable func-names */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import $ from 'jquery';
import Navigator from './Template/Navigator';
import UserPanel from './Template/UserPanel';

const Template = ({ module, children }) => {
  useEffect(() => {
    document.body.classList.remove('bg-gradient-primary');

    if ($(window).width() < 768) {
      $('.sidebar').addClass('toggled');
    }

    // Toggle the side navigation
    $('#sidebarToggle, #sidebarToggleTop, #sidebar-backdrop').on(
      'click',
      () => {
        $('body').toggleClass('sidebar-toggled');
        $('.sidebar').toggleClass('toggled');
        if ($('.sidebar').hasClass('toggled')) {
          $('.sidebar .collapse').collapse('hide');
        }
      },
    );

    // Close any open menu accordions when window is resized below 768px
    window.addEventListener('resize', () => {
      if ($(window).width() < 768) {
        $('.sidebar .collapse').collapse('hide');
      }
    });

    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    $('body.fixed-nav .sidebar').on(
      'mousewheel DOMMouseScroll wheel',
      function (e) {
        if ($(window).width() > 768) {
          const e0 = e.originalEvent;
          const delta = e0.wheelDelta || -e0.detail;
          this.scrollTop += (delta < 0 ? 1 : -1) * 30;
          e.preventDefault();
        }
      },
    );

    // Scroll to top button appear
    $(document).on('scroll', function () {
      const scrollDistance = $(this).scrollTop();
      if (scrollDistance > 100) {
        $('.scroll-to-top').fadeIn();
      } else {
        $('.scroll-to-top').fadeOut();
      }
    });

    // Smooth scrolling using jQuery easing
    $(document).on('click', 'a.scroll-to-top', function (e) {
      const $anchor = $(this);
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr('href')).offset().top,
          },
          1000,
          'easeInOutExpo',
        );
      e.preventDefault();
    });
  }, []);

  return (
    <div id="wrapper">
      <Helmet
        defer={false}
        title={`${module} | ${process.env.REACT_APP_TITLE}`}
      />
      <Navigator />
      <div className="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <UserPanel />
          <div className="container-fluid">{children}</div>
        </div>
        <footer className="bg-white sticky-footer">
          <div className="container my-auto">
            <div className="text-center my-auto copyright">
              <span>Copyright © Banco do Povo 2020</span>
            </div>
          </div>
        </footer>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Template;

import React, { useRef, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { globalEmitter } from '@logicamente.info/global-emitter';
import LgmtInput from '@logicamente.info/react-lgmt-input';
import { toast } from 'react-toastify';
import SelectMultipleCidades from '../Template/SelectMultipleCidades';
import APIController from '../../Controllers/APIController';

const AgenteDefault = {
  nome: '',
  email: '',
  telefone: '',
  situacao: true,
  cidades: [],
};

const ModalEditarAgente = ({ show, onClose, onSubmit }) => {
  const [agente, setAgente] = useState({ ...AgenteDefault });
  const inputNome = useRef();

  async function onFormSubmit(e) {
    e.preventDefault();
    const api = await APIController.getConnection();
    const ag = { ...agente };
    ag.cidades = ag.cidades.map((c) => c.label);
    await api.post('agente', ag);
    toast.success('As informações do agente foram salvas.');
    onSubmit();
  }

  async function onDelete() {
    const api = await APIController.getConnection();
    await api.delete(`agente/${agente._id}`);
    toast.error('O agente foi excluído.');
    onSubmit();
  }

  function onReset() {
    setAgente({ ...AgenteDefault });
  }

  function onLoad(agent) {
    const ag = { ...agent };
    ag.cidades = ag.cidades.map((c) => ({ value: c, label: c }));
    setAgente(ag);
  }

  function onChange(param, value) {
    const ag = { ...agente };
    ag[param] = value;
    setAgente(ag);
  }

  useEffect(() => {
    globalEmitter.on('@Agente:adicionar', onReset);
    globalEmitter.on('@Agente:editar', onLoad);
    return () => {
      globalEmitter.removeListener('@Agente:adicionar', onReset);
      globalEmitter.removeListener('@Agente:editar', onLoad);
    };
  }, []);

  useEffect(() => {
    if (show) inputNome.current.focus();
  }, [show]);

  return (
    <Modal size="lg" centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Dados do Agente</h4>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={onFormSubmit}>
        <Modal.Body>
          <div className="form-group">
            <label>Nome:</label>
            <input
              ref={inputNome}
              className="form-control"
              type="text"
              value={agente.nome}
              onChange={(e) => onChange('nome', e.target.value)}
              required
            />
          </div>
          <div className="form-row">
            <div className="col-md-7 col-lg-6">
              <div className="form-group">
                <label>Email:</label>
                <input
                  className="form-control"
                  type="email"
                  value={agente.email}
                  onChange={(e) => onChange('email', e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-5 col-lg-3">
              <div className="form-group">
                <label>Telefone:</label>
                <LgmtInput.Telefone
                  className="form-control"
                  value={agente.telefone}
                  onChange={(e) => onChange('telefone', e)}
                  required
                />
              </div>
            </div>
            <div className="col-md-5 col-lg-3">
              <div className="form-group">
                <label className="d-block">Situação:</label>
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    id="agente-ativo"
                    name="agente-situacao"
                    checked={agente.situacao}
                    onChange={() => onChange('situacao', true)}
                  />
                  <label
                    htmlFor="agente-ativo"
                    className="badge badge-success ml-1 mb-0"
                  >
                    Ativo
                  </label>
                  <input
                    type="radio"
                    className="ml-2"
                    id="agente-inativo"
                    name="agente-situacao"
                    checked={!agente.situacao}
                    onChange={() => onChange('situacao', false)}
                  />
                  <label
                    className="badge badge-danger ml-1 mb-0"
                    htmlFor="agente-inativo"
                  >
                    Inativo
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Cidades:</label>
            <SelectMultipleCidades
              value={agente.cidades}
              onChange={(e) => onChange('cidades', e)}
            />
          </div>
          {agente._id && (
            <p>
              <button
                className="text-danger btn btn-link btm-sm"
                type="button"
                onClick={onDelete}
              >
                <i className="far fa-trash-alt" /> excluir agente
              </button>
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-light" type="button" onClick={onClose}>
            Fechar
          </button>
          <button className="btn btn-success" type="submit">
            <i className="fas fa-save" />
            &nbsp;Salvar
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ModalEditarAgente;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { setGlobal } from '@logicamente.info/global-emitter';
import Template from './Template';
import Pagination from '../Utils/Pagination';
import APIController from '../Controllers/APIController';
import ModalEditarAgente from './Agentes/ModalEditarAgente';
import SelectCidades from './Template/SelectCidades';

const Agentes = () => {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [filtering, setFiltering] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [tipoAgente, setTipoAgente] = useState(true);
  const [agentes, setAgentes] = useState([]);
  const [agentesAtivos, setAgentesAtivos] = useState(0);
  const [agentesInativos, setAgentesInativos] = useState(0);
  const [editarAgente, setEditarAgente] = useState(false);

  async function carregarAgentes() {
    setEditarAgente(false);
    setLoading(true);
    const api = await APIController.getConnection();
    const params = { filter: {} };
    params.filter.situacao = tipoAgente;
    if (filter.nome) params.filter.nome = `/${filter.nome}/i`;
    if (filter.cidade) params.filter.cidades = filter.cidade;
    const { data } = await api.get(`agente/${page}/20`, { params });
    setTotalPages(data.pages);
    setAgentesAtivos(data.agentesAtivos);
    setAgentesInativos(data.agentesInativos);
    setAgentes(data.items);
    setLoading(false);
  }

  function exibirNomeCidades(cidades) {
    if (cidades.length === 0) return '-';
    if (cidades.length === 1) return cidades[0];
    return `${cidades[0]} e outras ${cidades.length - 1}`;
  }

  function adicionarAgente() {
    setGlobal('@Agente:adicionar', true);
    setEditarAgente(true);
  }

  function alterarAgente(agente) {
    setGlobal('@Agente:editar', agente);
    setEditarAgente(true);
  }

  function onChangeFilter(param, value) {
    const f = { ...filter };
    f[param] = value;
    setFilter(f);
  }

  function onResetFilter() {
    setFilter({});
    setFiltering(false);
  }

  function OnFilterSubmit(e) {
    e.preventDefault();
    setFiltering(true);
  }

  useEffect(() => {
    carregarAgentes();
  }, [tipoAgente, page, filtering]);

  return (
    <Template module="Solicitações">
      <Helmet>
        <title>Agentes | Banco do Povo - Painel</title>
      </Helmet>
      <div className="container-fluid">
        <div className="d-sm-flex justify-content-between align-items-center mb-4">
          <h3 className="text-dark mb-0 d-inline">Agentes</h3>
          <button
            type="button"
            onClick={adicionarAgente}
            className="btn btn-primary"
          >
            <i className="fa fa-plus"></i> Adicionar
          </button>
        </div>
        <div className="row">
          <div className="col">
            <form onSubmit={OnFilterSubmit}>
              <div className="form-row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="nome">Nome</label>
                    <input
                      className="form-control"
                      type="text"
                      id="nome"
                      value={filter.nome}
                      onChange={(e) => onChangeFilter('nome', e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="credito">Cidade</label>
                    <SelectCidades
                      value={
                        filter.cidade
                          ? { label: filter.cidade, value: filter.cidade }
                          : []
                      }
                      onChange={(e) => onChangeFilter('cidade', e.label)}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-4 d-sm-flex justify-content-sm-end align-items-sm-end justify-content-lg-start">
                  <div className="form-group">
                    <button className="btn btn-dark" type="submit">
                      <i className="fas fa-search" />
                      &nbsp;Filtrar
                    </button>
                    {filtering && (
                      <button
                        className="btn btn-link btn-sm text-danger"
                        onClick={onResetFilter}
                      >
                        <i className="fas fa-times" />
                        &nbsp;Limpar filtro
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <button
              className={`btn btn-block ${tipoAgente ? 'btn-primary' : 'btn-light'
              }`}
              type="button"
              onClick={() => setTipoAgente(true)}
            >
              Ativos ({agentesAtivos})
            </button>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <button
              className={`btn btn-block ${!tipoAgente ? 'btn-primary' : 'btn-light'
              }`}
              type="button"
              onClick={() => setTipoAgente(false)}
            >
              Inativos ({agentesInativos})
            </button>
          </div>
        </div>
        {loading && (
          <div className="row">
            <div className="col-12 text-center">
              <span className="spinner spinner-border" />
              <br />
              Carregando dados...
            </div>
          </div>
        )}
        {!loading && (
          <div className="row">
            <div className="col-12">
              {agentes.map((a) => (
                <div className="card my-2" key={a._id}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4">
                        <label className="d-flex justify-content-between m-0">
                          <strong>Nome</strong>
                        </label>
                        <label className="d-block">
                          {a.nome}
                          <br />
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <label className="d-flex justify-content-between m-0">
                          <strong>Email</strong>
                        </label>
                        <label className="d-block m-0">
                          {a.email}
                          <br />
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3 pb-0">
                        <label className="d-block m-0">
                          <strong>Telefone</strong>
                        </label>
                        <label className="d-block m-0">
                          {a.telefone}
                          <br />
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <label className="d-flex justify-content-between m-0">
                          <strong>Cidades</strong>
                        </label>
                        <label className="d-block m-0">
                          {exibirNomeCidades(a.cidades)}
                          <br />
                        </label>
                      </div>
                      <div className="col">
                        <label className="d-flex justify-content-between m-0">
                          <strong>Em análise</strong>
                        </label>
                        <label className="d-block m-0">
                          {a.emAnalise}
                          <br />
                        </label>
                      </div>
                      <div className="col">
                        <label className="d-flex justify-content-between m-0">
                          <strong>Aprovados</strong>
                        </label>
                        <label className="d-block m-0">
                          {a.aprovados}
                          <br />
                        </label>
                      </div>
                      <div className="col">
                        <label className="d-flex justify-content-between m-0">
                          <strong>Reprovados</strong>
                        </label>
                        <label className="d-block m-0">
                          {a.reprovados}
                          <br />
                        </label>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <div className="btn-group" role="group">
                          <button
                            className="btn btn-info"
                            type="button"
                            onClick={() => alterarAgente(a)}
                          >
                            <i className="far fa-edit" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col d-flex d-md-flex d-xl-flex justify-content-center">
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                onChangePage={setPage}
              />
            </div>
          </div>
        )}
      </div>
      <ModalEditarAgente
        show={editarAgente}
        onSubmit={carregarAgentes}
        onClose={() => setEditarAgente(false)}
      />
    </Template>
  );
};

export default Agentes;

import React, { useState, useEffect, Fragment } from 'react';
import { format } from 'date-fns-tz';
import { formatDistance } from 'date-fns';
import fnsBR from 'date-fns/locale/pt-BR';
import { Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import rondonia from '../Assets/rondonia.json';
import APIController from '../Controllers/APIController';
import { FormatCurrency } from '../Utils/Currency';
import ButtonImprimir from './Template/ButtonImprimir';

const DT_FORMAT = 'dd/MM/yyyy';
const DTH_FORMAT = 'dd/MM/yyyy HH:mm:ss';

const Imprimir = () => {
  const [params, setParams] = useState(null);
  const [data, setData] = useState(null);

  function carregarParametros() {
    const query = window.location.search;
    const jsn = new URLSearchParams(query);
    const p = JSON.parse(jsn.get('p'));
    delete p.filter.situacao;
    if (p.situacoes) p.filter.situacao = { $in: p.situacoes };
    setParams(p);
  }

  async function carregarDadosCidades(cidades) {
    const api = await APIController.getConnection();
    const dtCidades = [];
    for (let i = 0; i < cidades.length; i += 1) {
      const cidade = cidades[i];
      // eslint-disable-next-line no-await-in-loop
      const response = await api.get('solicitacao', {
        params: {
          filter: { ...params.filter, cidade },
          sort: 'nome',
          sortDirection: 1,
        },
      });
      if (response.data && response.data.length > 0) {
        const totalizadores = {
          Solicitado: 0,
          'Em análise': 0,
          Aprovado: 0,
          Reprovado: 0,
          valor: 0,
          CAD: 0,
          valorFinal: 0,
        };
        response.data.forEach((d) => {
          totalizadores[d.situacao] += 1;
          totalizadores.valor += d.valor;
          totalizadores.CAD += d.CAD;
          totalizadores.valorFinal += d.valorFinal;
        });
        dtCidades.push({
          cidade,
          solicitacoes: response.data,
          ...totalizadores,
        });
      }
    }
    return dtCidades;
  }

  function carregarDados() {
    if (params) {
      const { cidade } = params.filter;
      if (!cidade) carregarDadosCidades(rondonia).then(setData);
      else carregarDadosCidades(cidade.$in).then(setData);
    }
  }

  function exibirPeriodo() {
    const { dataHora } = params.filter;
    if (!dataHora) return 'Todos os registros';
    const { $lt, $gte } = dataHora;
    if ($lt && !$gte) return `Até ${format(new Date($lt), DT_FORMAT)}`;
    if ($gte && !$lt) return `A partir de ${format(new Date($gte), DT_FORMAT)}`;
    return `${format(new Date($lt), DT_FORMAT)} a ${format(
      new Date($gte),
      DT_FORMAT,
    )}`;
  }

  useEffect(carregarParametros, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(carregarDados, [params]);

  if (!params || !data) return <Spinner animation="border" role="status" />;

  return (
    <div className="print">
      <Helmet title="Relatório" />
      <ButtonImprimir />
      {data.map((row, i) => (
        <Fragment key={`tb_rep_${i}`}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th colSpan={13}>
                  <h5>Banco do Povo Digital - Relatório de Solicitações</h5>
                  <h6>
                    <b>{row.cidade}</b>
                  </h6>
                </th>
              </tr>
              <tr>
                <td colSpan={9}>
                  <b>Período:</b> {exibirPeriodo()}
                </td>
                <td colSpan={4}>
                  <b>Emitido em:</b> {format(new Date(), DTH_FORMAT)}
                </td>
              </tr>
              <tr>
                <th>Nome</th>
                <th>Modalidade</th>
                <th>Situação</th>
                <th>Motivo</th>
                <th>Data solicitação</th>
                <th>Encaminhado PAC</th>
                <th>Conclusão</th>
                <th>Agente</th>
                <th>Parc.</th>
                <th>Valor</th>
                <th>Juros</th>
                <th>CAD</th>
                <th>Final</th>
              </tr>
            </thead>
            <tbody>
              {row.solicitacoes?.map((s, j) => (
                <tr key={`tr_rep_${j}`}>
                  <td>{s.nome.toUpperCase()}</td>
                  <td>{s.tipoSolicitacao}</td>
                  <td>{s.situacao}</td>
                  <td>{s.motivoReprovacao}</td>
                  <td>{format(new Date(s.dataHora), DTH_FORMAT)}</td>
                  <td>
                    {(s.dataHoraEncaminhamento
                      || s.situacao === 'Solicitado') && (
                      <span>
                        {formatDistance(
                          new Date(s.dataHora),
                          new Date(s.dataHoraEncaminhamento ?? Date.now()),
                          { locale: fnsBR },
                        )}
                      </span>
                    )}
                  </td>
                  <td>
                    {(s.dataHoraConclusao || s.situacao === 'Em análise') && (
                      <span>
                        {formatDistance(
                          new Date(s.dataHoraEncaminhamento ?? s.dataHora),
                          new Date(s.dataHoraConclusao ?? Date.now()),
                          { locale: fnsBR },
                        )}
                      </span>
                    )}
                  </td>
                  <td>{s.agenteResponsavel?.nome ?? '-'}</td>
                  <td>{s.parcelas}</td>
                  <td>R$ {FormatCurrency(s.valor)}</td>
                  <td>{FormatCurrency(s.juros)}%</td>
                  <td>R$ {FormatCurrency(s.CAD)}</td>
                  <td>R$ {FormatCurrency(s.valorFinal)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={9} className="text-right">
                  <b>Solicitados:</b> {row.Solicitado} &emsp;
                  <b>Em análise:</b> {row['Em análise']} &emsp;
                  <b>Aprovados:</b> {row.Aprovado} &emsp;
                  <b>Reprovados:</b> {row.Reprovado} &emsp;
                </td>
                <td>
                  <b>R$ {FormatCurrency(row.valor)}</b>
                </td>
                <td />
                <td>
                  <b>R$ {FormatCurrency(row.CAD)}</b>
                </td>
                <td>
                  <b>R$ {FormatCurrency(row.valorFinal)}</b>
                </td>
              </tr>
            </tfoot>
          </table>
          <div className="page-break" />
        </Fragment>
      ))}
    </div>
  );

  // exibindo nome, cidade, datas, valores, motivos,
  // situações e totalizadores de solicitações, situações e
  // valores por cidade
};

export default Imprimir;

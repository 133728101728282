/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from '@logicamente.info/react-router-async-routes';
import AuthController from '../Controllers/AuthController';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    async
    render={async (props) => {
      const isLogged = await AuthController.isLogged();
      return isLogged
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/login',
          state: { from: props.location },
        }} />;
    }} />
);

export default PrivateRoute;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const ModalCarregando = ({ show, message }) => {
  const [showing, setShowing] = useState(show);
  const minimumTimeAlive = 500;
  const timeAlive = useRef(0);

  function showModal() {
    timeAlive.current = 0;
    setTimeout(() => {
      timeAlive.current += 100;
    }, 100);
    setShowing(true);
  }

  function hideModal() {
    const remainingTimeAlive = minimumTimeAlive - timeAlive.current;
    if (remainingTimeAlive <= 0) setShowing(false);
    else {
      setTimeout(() => {
        setShowing(false);
      }, remainingTimeAlive);
    }
  }

  useEffect(() => {
    if (show) showModal();
    else hideModal();
  }, [show]);

  return (
    <Modal show={showing} centered animation>
      <Modal.Header>
        <Modal.Title>
          <h4>Mensagem do Sistema</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center mb-3">
          <span className="spinner-border" />
          <br />
          {message}
        </p>
      </Modal.Body>
    </Modal>
  );
};

ModalCarregando.defaultProps = {
  show: false,
  message: 'Aguarde enquanto processamos a sua requisição...',
};

ModalCarregando.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
};

export default ModalCarregando;

import React from 'react';
import Select from 'react-select';
import rondonia from '../../Assets/rondonia.json';

const options = rondonia.map((c) => ({ value: c, label: c }));

function SelectCidades({ value, onChange }) {
  return (
    <Select
      options={options}
      value={value ?? []}
      onChange={onChange}
      placeholder="Selecione uma cidade..."
    />
  );
}

export default SelectCidades;

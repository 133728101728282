import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import logo from '../../Assets/img/logo-estrela.png';
import AuthController from '../../Controllers/AuthController';

function UserPanel() {
  return (
    <nav className="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
      <div className="container-fluid">
        <button
          className="btn btn-link d-md-none rounded-circle mr-3"
          id="sidebarToggleTop"
          type="button"
        >
          <i className="fas fa-bars" />
        </button>
        <ul className="nav navbar-nav flex-nowrap ml-auto">
          <div className="d-none d-sm-block topbar-divider" />
          <li className="nav-item dropdown no-arrow">
            <Dropdown className="nav-item no-arrow">
              <Dropdown.Toggle variant="link" className="nav-link">
                <span className="d-none d-lg-inline mr-2 text-gray-600 small">
                  Administrador
                </span>
                <img
                  className="border rounded-circle img-profile img-fluid"
                  src={logo}
                  alt="Administração"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow dropdown-menu-right animated--grow-in">
                <Dropdown.Item as="a" href="/configuracoes">
                  <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />{' '}
                  Configurações
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  as="button"
                  onClick={() => AuthController.logout()}
                >
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />{' '}
                  Sair
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default UserPanel;

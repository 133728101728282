/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';

export default class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxPageShow: 4,
      currentPage: this.props.currentPage ?? 1,
      gotoPage: this.props.currentPage ?? 1,
      pagination: [1],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const pagination = [];
    for (let i = 1; i <= props.totalPages; i += 1) pagination.push(i);
    const cP = state.currentPage !== props.currentPage
      ? props.currentPage
      : state.currentPage;
    state.pagination = pagination;
    state.currentPage = cP;
    state.gotoPage = cP;
    state.totalPages = props.totalPages;
    return {};
  }

  onPreviousPageClick() {
    if (this.state.currentPage > 1) this.onGotoPageClick(this.state.currentPage - 1);
  }

  onNextPageClick() {
    if (this.state.currentPage < this.props.totalPages) {
      this.onGotoPageClick(this.state.currentPage + 1);
    }
  }

  onGotoPageClick(page) {
    this.setState({ currentPage: page, gotoPage: page });
    this.props.onChangePage(page);
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.onGotoPageClick(parseInt(this.state.gotoPage, 10));
  }

  render() {
    if (this.state.totalPages > 1) {
      return (
        <div className="d-flex flex-column">
          <nav className="d-lg-flex justify-content-lg-center dataTables_paginate paging_simple_numbers">
            <ul className="pagination">
              <li className="page-item disabled">
                <button
                  className="page-link"
                  aria-label="Previous"
                  onClick={(e) => this.onPreviousPageClick(e)}
                  type="button"
                >
                  <span aria-hidden="true">«</span>
                </button>
              </li>
              {this.state.currentPage > this.state.maxPageShow + 1 ? (
                <li className="page-item">
                  <span className="page-link text-muted">...</span>
                </li>
              ) : null}
              {this.state.pagination.map((p, i) => {
                if (
                  p >= this.state.currentPage - this.state.maxPageShow
                  && p <= this.state.currentPage + this.state.maxPageShow
                ) {
                  return (
                    <li
                      key={i}
                      className={`page-item ${
                        this.state.currentPage === p ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link text-dark"
                        onClick={() => this.onGotoPageClick(p)}
                        type="button"
                      >
                        {p}
                      </button>
                    </li>
                  );
                }
                return null;
              })}
              {this.props.totalPages
              > this.state.currentPage + this.state.maxPageShow ? (
                <li className="page-item">
                  <span className="page-link text-muted">...</span>
                </li>
                ) : null}
              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Next"
                  onClick={(e) => this.onNextPageClick(e)}
                  type="button"
                >
                  <span aria-hidden="true">»</span>
                </button>
              </li>
            </ul>
          </nav>
          <nav className="d-lg-flex justify-content-lg-center mb-3">
            <form
              className="form-inline"
              onSubmit={(e) => this.onFormSubmit(e)}
            >
              <div className="form-group">
                <small className="d-flex justify-content-center">
                  <label>Página </label>&nbsp;
                </small>
                <input
                  type="number"
                  className="form-control form-control-sm"
                  value={this.state.gotoPage}
                  onChange={(e) => this.setState({ gotoPage: e.target.value })}
                  min="1"
                  max={this.props.totalPages}
                />
                &nbsp;
                <button type="submit" className="btn btn-primary btn-sm">
                  Ir
                </button>
              </div>
            </form>
          </nav>
        </div>
      );
    }
    return <div></div>;
  }
}

Pagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  maxPageShow: PropTypes.number,
  gotoPage: PropTypes.number,
  pagination: PropTypes.arrayOf(PropTypes.number),
};

import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import logo from '../Assets/img/logo-estrela.png';

function AppLoading({ error }) {
  return (
    <div className="d-flex vh-100 flex-column align-items-center justify-content-center">
      <img src={logo} alt="Banco do Povo de Rondônia" width="100" /><br />
      {!error && <Spinner animation="border" variant="primary" />}
      {error && (
        <Alert variant="danger">
          <p className="m-0">
            {JSON.stringify(error, null, 2)}
          </p>
        </Alert>
      )}
    </div>
  );
}

export default AppLoading;

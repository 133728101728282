import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../Assets/img/logo-estrela.png';

function Navigator() {
  return (
    <nav className="navbar navbar-dark sidebar sidebar-dark accordion p-0">
      <div className="container-fluid d-flex flex-row p-0 h-100">
        <div
          className="d-flex flex-column bg-gradient-primary h-100"
          id="sidebar-menu"
        >
          <NavLink
            className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0"
            to="/"
          >
            <div className="sidebar-brand-icon rotate-n-15">
              <img className="img-fluid" src={logo} alt="Banco do Povo" />
            </div>
            <div className="sidebar-brand-text mx-3">
              <span style={{ fontSize: '.7rem' }}>
                Painel
                <br />
                banco do povo
                <br />
              </span>
            </div>
          </NavLink>
          <hr className="sidebar-divider my-0" />
          <ul className="nav navbar-nav text-light" id="accordionSidebar">
            <li className="nav-item d-flex justify-content-center">
              <NavLink className="nav-link" exact to="/">
                <i className="far fa-file-alt" />
                <span>Solicitações</span>
              </NavLink>
            </li>
            <li className="nav-item d-flex justify-content-center">
              <NavLink className="nav-link" to="/agentes">
                <i className="fas fa-users" />
                <span>Agentes</span>
              </NavLink>
            </li>
            <li className="nav-item d-flex justify-content-center">
              <NavLink className="nav-link" to="/configuracoes">
                <i className="fas fa-cogs" />
                <span>Configurações</span>
              </NavLink>
            </li>
          </ul>
          <div className="text-center d-none d-md-inline">
            <button
              className="btn rounded-circle border-0"
              id="sidebarToggle"
              type="button"
            />
          </div>
        </div>
        <div className="d-sm-block d-md-none" id="sidebar-backdrop" />
      </div>
    </nav>
  );
}

export default Navigator;

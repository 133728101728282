import React, { useState } from 'react';

import { toast } from 'react-toastify';
import APIController from '../../Controllers/APIController';
import ModalCarregando from '../Template/ModalCarregando';

function AlterarSenha() {
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmacao, setConfirmacao] = useState('');
  const [salvando, setSalvando] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();
    setSalvando(true);
    const api = await APIController.getConnection();
    await api.post('auth/change-password', { password: novaSenha });
    setSalvando(false);
    setNovaSenha('');
    setConfirmacao('');
    toast.success('A senha foi alterada!');
  }

  return (
    <div className="card shadow">
      <div className="card-header py-3">
        <p className="text-primary m-0 font-weight-bold">Alterar senha</p>
      </div>
      <div className="card-body">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="address">
              <strong>Nova senha</strong>
            </label>
            <input
              className="form-control"
              type="password"
              placeholder="******"
              value={novaSenha}
              required
              minLength={6}
              onChange={(e) => setNovaSenha(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">
              <strong>Confirme a nova senha</strong>
            </label>
            <input
              className="form-control"
              type="password"
              placeholder="******"
              required
              value={confirmacao}
              pattern={`^${novaSenha}$`}
              onChange={(e) => setConfirmacao(e.target.value)}
            />
          </div>
          <div className="form-group text-right">
            <button className="btn btn-primary btn-sm" type="submit">
              <i className="fas fa-user-lock" />
              &nbsp;Alterar senha
            </button>
          </div>
        </form>
      </div>
      <ModalCarregando show={salvando} />
    </div>
  );
}

export default AlterarSenha;

/* eslint-disable class-methods-use-this */
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { Route } from '@logicamente.info/react-router-async-routes';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from '../Utils/PrivateRoute';
import Login from './Login';
import ScrollToTop from '../Utils/ScrollToTop';
import AuthController from '../Controllers/AuthController';
import Solicitacoes from './Solicitacoes';
import Configuracoes from './Configuracoes';
import Agentes from './Agentes';
import AppLoading from './AppLoading';
import Imprimir from './Imprimir';
import ImprimirSintetico from './ImprimirSintetico';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    AuthController.isLogged()
      .then((l) => {
        setIsLogged(l);
        setIsLoading(false);
      })
      .catch((e) => setLoadingError(e.message));
  }, []);

  if (isLoading) return <AppLoading error={loadingError} />;

  return (
    !isLogged
      ? <Login />
      : <Router>
        <ToastContainer />
        <ScrollToTop />
        <Switch>
          <Route path="/">
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="switch-wrapper"
            >
              <PrivateRoute exact path="/" component={Solicitacoes} />
              <PrivateRoute path="/agentes" component={Agentes} />
              <PrivateRoute
                exact
                path="/configuracoes"
                component={Configuracoes}
              />
              <PrivateRoute path="/imprimir" component={Imprimir} />
              <PrivateRoute path="/imprimir-sintetico" component={ImprimirSintetico} />
            </AnimatedSwitch>
          </Route>
        </Switch>
      </Router>
  );
};

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './Components/App';
import './Assets/bootstrap/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './Assets/style.css';
import './Assets/print.css';
import 'jquery/dist/jquery';
import 'jquery.easing/jquery.easing';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'date-input-polyfill';
import './Config/LocalForage';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

serviceWorker.unregister();

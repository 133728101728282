import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns-tz';
import { Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import rondonia from '../Assets/rondonia.json';
import APIController from '../Controllers/APIController';
import { FormatCurrency } from '../Utils/Currency';
import ButtonImprimir from './Template/ButtonImprimir';

const DT_FORMAT = 'dd/MM/yyyy';
const DTH_FORMAT = 'dd/MM/yyyy HH:mm:ss';

const ImprimirSintetico = () => {
  const [params, setParams] = useState(null);
  const [data, setData] = useState(null);

  const totalizar = useCallback((situacao) => data.reduce((p, c) => p + c[situacao], 0), [data]);

  function carregarParametros() {
    const query = window.location.search;
    const jsn = new URLSearchParams(query);
    const p = JSON.parse(jsn.get('p'));
    delete p.filter.situacao;
    if (p.situacoes) p.filter.situacao = { $in: p.situacoes };
    setParams(p);
  }

  async function carregarDadosCidades(cidades) {
    const api = await APIController.getConnection();
    const dtCidades = [];
    for (let i = 0; i < cidades.length; i += 1) {
      const cidade = cidades[i];
      // eslint-disable-next-line no-await-in-loop
      const response = await api.get('solicitacao', {
        params: {
          filter: { ...params.filter, cidade },
          sort: 'nome',
          sortDirection: 1,
        },
      });
      if (response.data && response.data.length > 0) {
        const totalizadores = {
          Solicitado: 0,
          'Em análise': 0,
          Aprovado: 0,
          Reprovado: 0,
          valor: 0,
          CAD: 0,
          valorFinal: 0,
        };
        response.data.forEach((d) => {
          totalizadores[d.situacao] += 1;
          totalizadores.valor += d.valor;
          totalizadores.CAD += d.CAD;
          totalizadores.valorFinal += d.valorFinal;
        });
        dtCidades.push({
          cidade,
          solicitacoes: response.data,
          ...totalizadores,
        });
      }
    }
    return dtCidades;
  }

  function carregarDados() {
    if (params) {
      const { cidade } = params.filter;
      if (!cidade) carregarDadosCidades(rondonia).then(setData);
      else carregarDadosCidades(cidade.$in).then(setData);
    }
  }

  function exibirPeriodo() {
    const { dataHora } = params.filter;
    if (!dataHora) return 'Todos os registros';
    const { $lt, $gte } = dataHora;
    if ($lt && !$gte) return `Até ${format(new Date($lt), DT_FORMAT)}`;
    if ($gte && !$lt) return `A partir de ${format(new Date($gte), DT_FORMAT)}`;
    return `${format(new Date($lt), DT_FORMAT)} a ${format(
      new Date($gte),
      DT_FORMAT,
    )}`;
  }

  useEffect(carregarParametros, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(carregarDados, [params]);

  if (!params || !data) return <Spinner animation="border" role="status" />;

  return (
    <div className="">
      <ButtonImprimir />
      <Helmet title="Relatório (Sintético)" />
      <table className="table table-bordered table-sm">
        <thead>
          <tr>
            <th colSpan={6}>
              <h4>Banco do Povo Digital - Relatório de Solicitações (Sintético)</h4>
            </th>
          </tr>
          <tr>
            <td colSpan={4}>
              <b>Período:</b> {exibirPeriodo()}
            </td>
            <td colSpan={2}>
              <b>Emitido em:</b> {format(new Date(), DTH_FORMAT)}
            </td>
          </tr>
          <tr>
            <th>Cidade</th>
            <th>Solicitados</th>
            <th>Em análise</th>
            <th>Aprovados</th>
            <th>Reprovados</th>
            <th>Valor total</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={i}>
              <td>{row.cidade}</td>
              <td>{row.Solicitado}</td>
              <td>{row['Em análise']}</td>
              <td>{row.Aprovado}</td>
              <td>{row.Reprovado}</td>
              <td className="text-right">
                R$ {FormatCurrency(row.valor)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="font-weight-bold">
            <td>Total Geral</td>
            <td>{totalizar('Solicitado')}</td>
            <td>{totalizar('Em análise')}</td>
            <td>{totalizar('Aprovado')}</td>
            <td>{totalizar('Reprovado')}</td>
            <td className="text-right">
              R$ {FormatCurrency(totalizar('valor'))}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );

  // exibindo nome, cidade, datas, valores, motivos,
  // situações e totalizadores de solicitações, situações e
  // valores por cidade
};

export default ImprimirSintetico;

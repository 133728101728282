import React from 'react';
import { Button } from 'react-bootstrap';

function ButtonImprimir() {
  return (
        <Button
        style={{
          position: 'fixed',
          top: 15,
          right: 15,
        }}
         onClick={window.print} className="d-print-none">
            <i className="fa fa-print" />
        </Button>
  );
}

export default ButtonImprimir;

/* eslint-disable import/no-cycle */
import axios from 'axios';
import AuthController from './AuthController';

class APIController {
  static async getConnection() {
    return axios.create({
      baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : process.env.REACT_APP_API,
      headers: { Authorization: `Bearer ${await AuthController.getToken()}` },
    });
  }
}

export default APIController;

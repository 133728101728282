import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import AuthController from '../Controllers/AuthController';
import APIController from '../Controllers/APIController';
import logo from '../Assets/img/logo-estrela.png';

const Login = () => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('bg-gradient-primary');
  }, []);

  function onFormSubmit(e) {
    e.preventDefault();
    setErrorMessage(null);
    setLoading(true);
    APIController.getConnection().then((api) => {
      api
        .post('/auth/login', { password })
        .then((res) => {
          AuthController.setUser({ panel: true }, res.data.token);
          window.location.reload();
        })
        .catch(() => {
          setErrorMessage('Senha inválida');
        })
        .then(() => {
          setLoading(false);
        });
    });
  }

  return (
    <div className="container">
      <Helmet title={'Login - Banco do Povo - Painel'} />
      <div className="row justify-content-center vh-100">
        <div className="col-md-9 col-lg-12 col-xl-10 d-flex flex-column justify-content-center">
          <div className="card shadow-lg o-hidden border-0 my-5">
            <div className="card-body p-0">
              {loading && (
                <div className="row">
                  <div className="col">
                    <p className="text-center p-5">
                      <span className="spinner-border"></span>
                      <br />
                      Verificando credenciais...
                    </p>
                  </div>
                </div>
              )}
              {!loading && (
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-flex">
                    <div
                      className="flex-grow-1 bg-login-image"
                      style={{
                        backgroundImage: `url("${logo}")`,
                        backgroundSize: '50%',
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h4 className="text-dark mb-4">Bem-vindo de volta!</h4>
                        {errorMessage && (
                          <div className="alert alert-danger">
                            {errorMessage}
                          </div>
                        )}
                      </div>
                      <form className="user" onSubmit={onFormSubmit}>
                        <div className="form-group">
                          <input
                            className="form-control form-control-user text-center"
                            type="password"
                            placeholder="******"
                            autoFocus={true}
                            autoComplete="off"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <button
                          className="btn btn-primary btn-block text-white btn-user"
                          type="submit"
                        >
                          Entrar
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  title: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  rememberme: PropTypes.bool,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
};

export default Login;

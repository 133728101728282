import { globalEmitter } from '@logicamente.info/global-emitter';
import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import LgmtInput from '@logicamente.info/react-lgmt-input';
import APIController from '../../Controllers/APIController';
import SelectCidades from '../Template/SelectCidades';
import { FormatCurrency } from '../../Utils/Currency';

function ModalSolicitacao({ show, onClose, onSubmit }) {
  const [solicitacao, setSolicitacao] = useState(null);
  const inputNome = useRef();

  function avaliarCorBadge(tipo) {
    switch (tipo) {
      case 'Crédito - Giro': return 'badge-primary';
      case 'Crédito - Fixo': return 'badge-info';
      case 'Crédito Pessoal': return 'badge-warning';
      default: return 'badge-success';
    }
  }

  function exibirBadge(tipo) {
    const color = avaliarCorBadge(tipo);
    return (
      <span className={`badge d-xl-flex justify-content-xl-center ${color}`}>
        {tipo}
      </span>
    );
  }

  async function onFormSubmit(e) {
    e.preventDefault();
    const api = await APIController.getConnection();
    await api.put('solicitacao', solicitacao);
    toast.success('As informações da solicitação foram salvas.');
    onSubmit();
  }

  async function onDelete() {
    const api = await APIController.getConnection();
    await api.delete(`solicitacao/${solicitacao._id}`);
    toast.error('A solicitação foi excluída.');
    onSubmit();
  }

  function onLoad(sol) {
    setSolicitacao({ ...sol });
  }

  function onChange(param, value) {
    const sol = { ...solicitacao };
    sol[param] = value;
    setSolicitacao(sol);
  }

  function onChangeRg(param, value) {
    const sol = { ...solicitacao };
    sol.rg[param] = value;
    setSolicitacao(sol);
  }

  useEffect(() => {
    globalEmitter.on('@Solicitacao:editar', onLoad);
    return () => {
      globalEmitter.removeListener('@Solicitacao:editar', onLoad);
    };
  }, []);

  useEffect(() => {
    if (show) inputNome.current.focus();
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} size="lg" animation="fade">
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Dados da Solicitação</h4>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={onFormSubmit}>
        {solicitacao && (
          <Modal.Body>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="d-block">Tipo de Crédito:</label>
                  {exibirBadge(solicitacao.tipoSolicitacao)}
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="d-block">Data da solicitação:</label>
                  <label className="d-block">
                    <strong>
                      {format(new Date(solicitacao.dataHora), 'dd/MM/yyyy')}
                    </strong>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label className="d-block">Agente responsável:</label>
                  <label className="d-block">
                    <strong>{solicitacao.agenteResponsavel?.nome}</strong>
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="d-block">Telefone do agente:</label>
                  <label className="d-block">
                    <strong>{solicitacao.agenteResponsavel?.telefone}</strong>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Nome:</label>
              <input
                className="form-control"
                type="text"
                value={solicitacao.nome}
                onChange={(e) => onChange('nome', e.target.value)}
                ref={inputNome}
              />
            </div>
            <div className="form-row">
              <div className="col-sm-12 col-lg-5">
                <div className="form-group">
                  <label>CPF:</label>
                  <LgmtInput.CPF
                    className="form-control"
                    value={solicitacao.cpf}
                    onChange={(e) => onChange('cpf', e)}
                  />
                </div>
              </div>
              <div className="col-md-5 col-lg-3">
                <div className="form-group">
                  <label>RG:</label>
                  <input
                    className="form-control"
                    type="text"
                    value={solicitacao.rg.numero}
                    onChange={(e) => onChangeRg('numero', e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-2">
                <div className="form-group">
                  <label>Órgão Exp.:</label>
                  <input
                    className="form-control"
                    type="text"
                    value={solicitacao.rg.orgaoExpedidor}
                    onChange={(e) => onChangeRg('orgaoExpedidor', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-2">
                <div className="form-group">
                  <label>UF Exp.:</label>
                  <select
                    className="custom-select"
                    value={solicitacao.rg.estado}
                    onChange={(e) => onChangeRg('estado', e.target.value)}
                  >
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AM">AM</option>
                    <option value="AP">AP</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MG">MG</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="PR">PR</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="RS">RS</option>
                    <option value="SC">SC</option>
                    <option value="SE">SE</option>
                    <option value="SP">SP</option>
                    <option value="TO">TO</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-5 col-lg-3">
                <div className="form-group">
                  <label>Telefone:</label>
                  <label className="d-block">
                    <strong>(69) {solicitacao.telefone}</strong>
                  </label>
                </div>
              </div>
              <div className="col-md-7 col-lg-5">
                <div className="form-group">
                  <label>Cidade:</label>
                  <SelectCidades
                    value={{
                      label: solicitacao.cidade,
                      value: solicitacao.cidade,
                    }}
                    onChange={(e) => onChange('cidade', e.label)}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    className="form-control"
                    type="email"
                    value={solicitacao.email}
                    onChange={(e) => onChange('email', e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Atividade profissional:</label>
                  <input
                    className="form-control"
                    type="text"
                    value={solicitacao.atividadeProfissional}
                    onChange={(e) => onChange('atividadeProfissional', e.target.value)
                    }
                  />
                </div>
              </div>
              {solicitacao.tipoSolicitacao.match(/^Crédito -/g) && (
                <>
                  <div className="col-sm-12 col-lg-4">
                    <div className="form-group">
                      <label>Empresa:</label>
                      <input
                        className="form-control"
                        type="text"
                        value={solicitacao.nomeEmpresa}
                        onChange={(e) => onChange('nomeEmpresa', e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>CNPJ:</label>
                      <LgmtInput.CNPJ
                        className="form-control"
                        value={solicitacao.cnpj}
                        onChange={(e) => onChange('cnpj', e)}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row">
              <div className="col">
                Solicitado: <br />
                <strong>R$ {FormatCurrency(solicitacao.valor)}</strong>
              </div>
              <div className="col">
                CAD: <br />
                <strong>R$ {FormatCurrency(solicitacao.CAD)}</strong>
              </div>
              <div className="col">
                Empréstimo: <br />
                <strong>
                  R$
                  {FormatCurrency(solicitacao.valor + solicitacao.CAD)}
                </strong>
              </div>
              <div className="col">
                Juros: <br />
                <strong>{FormatCurrency(solicitacao.juros)} %</strong>
              </div>
              <div className="col">
                Parcelas: <br />
                <strong>
                  {solicitacao.parcelas}x R${' '}
                  {FormatCurrency(
                    solicitacao.valorFinal / solicitacao.parcelas,
                  )}
                </strong>
              </div>
              <div className="col">
                Final: <br />
                <strong>R$ {FormatCurrency(solicitacao.valorFinal)}</strong>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                Observações: <br />
                <textarea className="form-control"
                  onChange={(e) => onChange('observacao', e.target.value)}
                  value={solicitacao.observacao ?? ''} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <button
                  className="btn btn-sm btn-link text-danger"
                  type="button"
                  onClick={onDelete}
                >
                  <i className="fa fa-trash" /> excluir solicitação
                </button>
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <button className="btn btn-light" type="button" onClick={onClose}>
            Fechar
          </button>
          <button className="btn btn-success" type="submit">
            <i className="fas fa-save" />
            &nbsp;Salvar
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default ModalSolicitacao;

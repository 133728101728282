import React, { useState } from 'react';

import LgmtInput from '@logicamente.info/react-lgmt-input';
import { toast } from 'react-toastify';
import { FormatCurrency } from '../../Utils/Currency';
import APIController from '../../Controllers/APIController';
import ModalCarregando from '../Template/ModalCarregando';

function Microcredito({ configuracao }) {
  const [micro, setMicro] = useState(configuracao);
  const [salvando, setSalvando] = useState(false);

  function setConfiguracao(param, value) {
    const c = { ...micro };
    c[param] = value;
    setMicro(c);
  }

  async function onSubmit(e) {
    e.preventDefault();
    setSalvando(true);
    const api = await APIController.getConnection();
    await api.post('configuracao/micro', micro);
    setSalvando(false);
    toast.success('As configurações alteradas!');
  }

  return (
    <div className="card shadow mb-3">
      <div className="card-header py-3">
        <p className="text-primary m-0 font-weight-bold">Microcrédito</p>
      </div>
      <div className="card-body">
        <form onSubmit={onSubmit}>
          <div className="form-row">
            <div className="col-sm-6 col-lg-3">
              <div className="form-group">
                <label htmlFor="username">
                  <strong>Parcelas mínimo</strong>
                </label>
                <input
                  className="form-control"
                  type="number"
                  min={1}
                  required
                  value={micro.parcelasMinimo}
                  onChange={(e) => setConfiguracao(
                    'parcelasMinimo',
                    parseInt(e.target.value, 10),
                  )
                  }
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="form-group">
                <label htmlFor="username">
                  <strong>Parcelas máximo</strong>
                </label>
                <input
                  className="form-control"
                  type="number"
                  min={1}
                  required
                  value={micro.parcelasMaximo}
                  onChange={(e) => setConfiguracao(
                    'parcelasMaximo',
                    parseInt(e.target.value, 10),
                  )
                  }
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="form-group">
                <label htmlFor="username">
                  <strong>Valor mínimo</strong>
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">R$</span>
                  </div>
                  <LgmtInput.Moeda
                    className="form-control text-right"
                    type="text"
                    required
                    value={FormatCurrency(micro.valorMinimo)}
                    onChange={(v) => setConfiguracao(
                      'valorMinimo',
                      parseInt(v.replace(/\D/g, ''), 10),
                    )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="form-group">
                <label htmlFor="username">
                  <strong>Valor máximo</strong>
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">R$</span>
                  </div>
                  <LgmtInput.Moeda
                    className="form-control text-right"
                    type="text"
                    required
                    value={FormatCurrency(micro.valorMaximo)}
                    onChange={(v) => setConfiguracao(
                      'valorMaximo',
                      parseInt(v.replace(/\D/g, ''), 10),
                    )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="form-group">
                <label htmlFor="username">
                  <strong>Juros</strong>
                </label>
                <div className="input-group">
                  <LgmtInput.Moeda
                    className="form-control text-right"
                    type="text"
                    required
                    value={FormatCurrency(micro.juros)}
                    onChange={(v) => setConfiguracao(
                      'juros',
                      parseInt(v.replace(/\D/g, ''), 10),
                    )
                    }
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group text-right">
            <button className="btn btn-success btn-sm" type="submit">
              <i className="fas fa-save" />
              &nbsp;Salvar
            </button>
          </div>
        </form>
      </div>
      <ModalCarregando show={salvando} />
    </div>
  );
}

export default Microcredito;

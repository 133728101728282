import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { globalEmitter } from '@logicamente.info/global-emitter';
import { toast } from 'react-toastify';
import APIController from '../../Controllers/APIController';

function ModalEncaminhar({ show, onClose, onSubmit }) {
  const [solicitacao, setSolicitacao] = useState(null);
  const [agentes, setAgentes] = useState([]);
  const inputAgente = useRef();

  async function carregarAgentes() {
    const api = await APIController.getConnection();
    const { data } = await api.get('agente');
    setAgentes(data.map((a) => ({ value: a._id, label: a.nome })));
  }

  async function onFormSubmit(e) {
    e.preventDefault();
    const api = await APIController.getConnection();
    await api.patch(`solicitacao/${solicitacao._id}`, solicitacao);
    toast.success('A solicitação foi encaminhada para o agente selecionado.');
    onSubmit();
  }

  function onLoad(sol) {
    setSolicitacao({ ...sol });
  }

  function onChange(param, value) {
    const sol = { ...solicitacao };
    sol[param] = value;
    sol.situacao = 'Em análise';
    setSolicitacao(sol);
  }

  useEffect(() => {
    carregarAgentes();
    globalEmitter.on('@Solicitacao:encaminhar', onLoad);
    return () => {
      globalEmitter.removeListener('@Solicitacao:encaminhar', onLoad);
    };
  }, []);

  useEffect(() => {
    if (show) inputAgente.current.focus();
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} centered animation="fade">
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Encaminhar para Agente</h4>
        </Modal.Title>
      </Modal.Header>
      {solicitacao && (
        <form onSubmit={onFormSubmit}>
          <Modal.Body>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <Select
                    options={agentes}
                    value={agentes.find(
                      (a) => a.value === solicitacao.agenteResponsavel,
                    )}
                    onChange={(e) => onChange('agenteResponsavel', e.value)}
                    placeholder="Selecione um agente..."
                    ref={inputAgente}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-light" type="button" onClick={onClose}>
              Fechar
            </button>
            <button className="btn btn-primary" type="submit">
              <i className="far fa-envelope" />
              &nbsp;Encaminhar
            </button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
}

export default ModalEncaminhar;

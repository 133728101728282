import React, { useState, useEffect } from 'react';


import APIController from '../Controllers/APIController';
import AlterarSenha from './Configuracoes/AlterarSenha';
import Cantinho from './Configuracoes/Cantinho';
import Microcredito from './Configuracoes/Microcredito';
import Pessoal from './Configuracoes/Pessoal';
import Template from './Template';

function Configuracoes() {
  const [configuracoes, setConfiguracoes] = useState(null);

  async function carregarConfiguracoes() {
    const api = await APIController.getConnection();
    const { data } = await api.get('configuracao');
    setConfiguracoes(data);
  }

  useEffect(() => {
    carregarConfiguracoes();
  }, []);

  return (
    <Template module="Configurações">
      <h3 className="text-dark mb-4">Configurações</h3>
      <div className="row mb-3">
        <div className="col">
          {!configuracoes && (
            <p className="text-center">
              <span className="spinner-border" />
              <br />
              Carregando configurações...
            </p>
          )}
          {configuracoes && (
            <div className="row">
              <div className="col">
                <Microcredito configuracao={configuracoes.micro} />
                <Pessoal configuracao={configuracoes.pessoal} />
                <Cantinho configuracao={configuracoes.cantinho} />
                <AlterarSenha />
              </div>
            </div>
          )}
        </div>
      </div>
    </Template>
  );
}

export default Configuracoes;

import { globalEmitter } from '@logicamente.info/global-emitter';
import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { toast } from 'react-toastify';
import APIController from '../../Controllers/APIController';

const MotivoReprovacao = [
  'Desistência',
  'Restrição cadastral',
  'Sem garantia',
  'Área de atuação',
  'Falta documentação',
];

function ModalAlterarSituacao({ show, onClose, onSubmit }) {
  const [solicitacao, setSolicitacao] = useState(null);
  const inputSituacao = useRef();

  async function onFormSubmit(e) {
    e.preventDefault();
    const api = await APIController.getConnection();
    await api.patch(`solicitacao/${solicitacao._id}`, solicitacao);
    toast.success(
      `A solicitação mudou de situação para: ${solicitacao.situacao}.`,
    );
    onSubmit();
  }

  function onLoad(sol) {
    setSolicitacao({ ...sol, situacao: 'Aprovado' });
  }

  function onChange(param, value) {
    const sol = { ...solicitacao };
    sol[param] = value;
    setSolicitacao(sol);
  }

  useEffect(() => {
    globalEmitter.on('@Solicitacao:situacao', onLoad);
    return () => {
      globalEmitter.removeListener('@Solicitacao:situacao', onLoad);
    };
  }, []);

  useEffect(() => {
    if (show) inputSituacao.current.focus();
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} centered animation="fade">
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Alterar situação</h4>
        </Modal.Title>
      </Modal.Header>
      {solicitacao && (
        <form onSubmit={onFormSubmit}>
          <Modal.Body>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <select
                    className="custom-select"
                    required
                    value={solicitacao?.situacao}
                    onChange={(e) => onChange('situacao', e.target.value)}
                    ref={inputSituacao}
                  >
                    <option value="Aprovado">Aprovada</option>
                    <option value="Reprovado">Reprovada</option>
                  </select>
                </div>
                {solicitacao?.situacao === 'Reprovado'
                  && <div className="form-group">
                    <label>Motivo da reprovação:</label>
                    <select className="form-control" required
                    value={solicitacao?.motivoReprovacao}
                    onChange={(e) => onChange('motivoReprovacao', e.target.value)}>
                      <option></option>
                      {MotivoReprovacao.map((m, i) => (
                        <option key={`motivo_rep_${i}`} value={m}>{m}</option>
                      ))}
                    </select>
                  </div>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-light" type="button" onClick={onClose}>
              Fechar
            </button>
            <button className="btn btn-warning" type="submit">
              <i className="fas fa-exchange-alt" /> Alterar
            </button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
}

export default ModalAlterarSituacao;
